.contactform{
    padding: 55px 0 55px 0;
    border-bottom: 1px solid rgba(128, 128, 128, 0.14);
}

.contactform .container{
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactform-description{
    font-size: 21px;
    line-height: 11px;
    font-weight: 500;
    color: grey;
    margin-bottom: 5px;
}


.contactform-header{
    font-size: 45px;
    line-height: 45px;
    margin-top: 15px;
    font-weight: 500;
    text-align: center;
}

.contactform-items{
    display: flex;
    justify-content: center;
    margin: 55px 0 25px 0;
    gap: 29px;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    
}

.contactform-item{
    flex: 1 1 calc(33% - 29px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 11px;
}

.contactform-item > *{
    text-align: center;
    
}

.contactform-item .icon{
    font-size: 25px;
}

.contactform-item > h3{
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
}

.contactform-item > p{
    color: rgb(94, 93, 93);
    font-weight: 300;
    width: 71%;
}

.contactform-item a{
    text-decoration: none;
    color: rgb(77, 76, 76);
}

form{
    border-top: 1px solid rgba(200, 192, 192, 0.483);
    width: 100%;
    margin-top: 55px;
    margin-bottom: 25px;
    padding-top: 55px;
}

.form-row{
    display: flex;
    gap: 25px;
    padding-bottom: 25px;
    flex-wrap: wrap;
    
}

.form-col{
    flex: 1;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
    
}

form input[type="text"]{
    width: 100%;
    height: 29px;
    border: 1px solid rgba(200, 192, 192, 0.483);
    min-width: 190px;
    padding-left: 9px;
    font-weight: 500;
    color: grey;
}



form input[type="submit"]{
    width: 100%;
    border: 1px solid rgba(200, 192, 192, 0.483);
    max-width: 125px;
    background-color: black;
    color: white;
    font-weight: 500;
    font-size: 15px;
    margin-left: auto;
    padding: 9px 11px;
    border: none;
}

form textarea{
    width: 100%;
    height: 100%;
    min-width: 150px;

    min-height: 86px;
    border: 1px solid rgba(200, 192, 192, 0.483);
    resize: none;
    padding-left: 9px;
    padding-top: 5px;
    font-weight: 500;
    color: grey;
}

@media only screen and (min-width:426px){

    .contactform-items{
        display: flex;
        justify-content: center;
        margin: 55px 0 25px 0;
        gap: 29px;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        
    }
    
    .contactform-item{
        flex: 1 1 calc(24% - 29px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 11px;
    }

}


