.showcase2{
    padding: 75px 0 75px 0;

}

.showcase2 .container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.showcase2-description{
    font-size: 21px;
    line-height: 11px;
    font-weight: 500;
    color: grey;
    margin-bottom: 5px;
}


.showcase2-header{
    font-size: 37px;
    line-height: 45px;
    margin-top: 15px;
    font-weight: 500;
    margin-bottom: 55px;
    text-align: center;
}

.showcase2-content{
    display: flex;
}

.showcase2-content > div{
    flex: 1;
}

.showcase2-image{
    display: none;
}

.showcase2-image > img{
    width: 100%;
    height:100%;
    object-fit: cover;
}

.showcase2-items{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgb(213, 213, 213);
    gap: 1px;
}

.showcase2-item{
    flex: 95%;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(244, 244, 244);
    padding: 15px;
}

.showcase2-item:hover{
    background-color: rgb(0, 0, 0);
    cursor: pointer;
}

.showcase2-item > *{
    text-align: center;
}

.showcase2-item:hover h3{
    color: rgb(213, 213, 213);
}

.showcase2-item > h3{
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
    margin-top: 25px;
    
}


@media only screen and (min-width:426px) {

    .showcase2-items{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        background-color: rgb(213, 213, 213);
        gap: 1px;
    }
    
    .showcase2-item{
        flex: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgb(244, 244, 244);
        padding: 15px;
    }
}

@media only screen and (min-width:768px) {
    .showcase2-image{
        display: block;
    }

    .showcase2-items{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        background-color: rgb(213, 213, 213);
        gap: 5px;
    }
    
    .showcase2-item{

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgb(244, 244, 244);
        padding: 15px;
    }
}