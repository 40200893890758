.counts{
    border-bottom: 1px solid rgba(128, 128, 128, 0.14);
}

.counts .container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.counts-items{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 5px;
}


.counts-item{
    min-width: 100%;
    flex: 1 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    gap: 11px;
    height: 250px;
    border-top: 1px solid rgba(227, 227, 227, 0.716);
}

.counts-item:hover{
    cursor: pointer;
}

.counts-item > *{
    text-align: center;
}

.counts-name{
    color: black;
    color: grey;
}

.counts-count{
    font-size: 41px;
    color:black;
    font-weight: 400;
    
}

@media only screen and (min-width:768px) {


    .counts-items{
        flex-wrap: nowrap;
        gap: 5px;
        justify-content: center;
    }

    .counts-item{
        min-width: 33%;
        max-width: 375px;
        border-top: none;
    }
}

